import React, { useEffect, useRef, useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Container, ColumnSeparator, CustomTableCell } from './styles';

export interface CustomGridColumns {
  field: string;
  headerName: string;
  width: number;
  minWidth?: number;
  align?: string;
  fixed?: boolean;
  hide?: boolean;
  sortable?: boolean;
  disableColumnMenu?: boolean;
  renderCell?: (row: any) => void;
  fixedDirection?: string;
}

interface FormProps {
  rows: any[];
  columns: CustomGridColumns[];
  stickyMenuBackground?: string;
}

/**
 * Linhas e colunas para exemplo
 */

/**
 * @param rows Array de objetos contendo as linhas da tabela (as keys dos objetos devem ser iguais as ids das colunas)
 * @param columns Array de objetos contendo as colunas
 * @param columns Para fixar uma coluna basta passar uma chave chamada fixed com o valor true e Left com 0 (também é necessário passar um width e um minWidth)
 * @param columns Caso mais de uma coluna seja fixa, a coluna seguinte deverá receber o valor de Left igual ao minWidth da coluna anterior
 * @returns Componente DataGrid personalizado
 */

const CustomDataGrid: React.FC<FormProps> = (props) => {
  const { rows, columns, stickyMenuBackground } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const tableRef = useRef<any>();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef.current.scrollTop = 0;
  };

  useEffect(() => {
    if (rows.length === 0) {
      setPage(0);
    }
  }, [rows]);

  const visibleColumns = columns.filter((column) => !column.hide);

  // Função auxiliar para criar células
  const createCell = (column: any, row: any) => {
    const cellContent = column.renderCell
      ? column.renderCell(row)
      : row[column.field];
    if (column.fixed) {
      return (
        <Tooltip
          title={`Seq: ${row.num_item} - ${row.des_produto} - Código do Produto: ${row.cod_produto}`}
          placement="bottom"
        >
          <CustomTableCell
            key={column.field}
            background={stickyMenuBackground}
            columnWidth={column.width}
          >
            {cellContent}
          </CustomTableCell>
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title={`Seq: ${row.num_item} - ${row.des_produto} - Código do Produto: ${row.cod_produto}`}
        placement="bottom"
      >
        <TableCell key={column.field}>{cellContent}</TableCell>
      </Tooltip>
    );
  };

  return (
    <Container>
      <Paper
        style={{
          width: '100%',
          maxHeight: '500px',
          flex: 1,
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TableContainer
          style={{
            flex: 1,
          }}
          ref={tableRef}
          className="tableEmissaoNFeContainer"
        >
          <Table id="tableEmissaoNFe">
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.num_item}
                    style={{
                      borderColor: '#8850BF',
                    }}
                  >
                    {visibleColumns.map((column) => createCell(column, row))}
                  </TableRow>
                ))}
            </TableBody>
            {/* TableHead vem após o TableBody pois o context de empilhamento e renderização fica invertido no uso do postition sticky */}
            <TableHead className="headerPosition">
              <TableRow>
                {visibleColumns.map((column) => (
                  <TableCell
                    key={column.field}
                    className="text-truncate"
                    style={{
                      position: column.fixed ? 'sticky' : 'relative',
                      right: column.fixed ? 0 : undefined,
                      width: column.width,
                      minWidth: column.width,
                      background: column.fixed ? '#57069e' : undefined,
                      zIndex: 99999,
                      textAlign: column.fixed ? 'center' : undefined,
                    }}
                  >
                    {column.headerName}
                    {!column.fixed && (
                      <ColumnSeparator>
                        <svg
                          className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M11 19V5h2v14z" />
                        </svg>
                      </ColumnSeparator>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ height: '150px' }}
          rowsPerPageOptions={[-1]}
          onPageChange={handleChangePage}
          count={rows.length}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Paper>
    </Container>
  );
};

export default CustomDataGrid;
