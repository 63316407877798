import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ConfirmButton } from '~/components/Buttons';
import {
  InputAsyncSelect,
  InputNumber,
  InputSelect,
} from '~/components/NovosInputs';
import { InputMoney } from '~/components/NovosInputs/InputMoney2';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import { useEmissaoNFE } from '~/pages/EmissaoNFE/EmissaoNFEContext';
import { CalculosDaTabelaManual } from '~/pages/EmissaoNFE/functions/Calculos';
import { moneyFormat } from '~/utils/functions';
import { Container, EntradaItensContainer } from './styles';
import { ListaDeCFOP, Produto } from '../../../../protocols';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import itensNfe from '../../../../defaultData/itens.json';
import { DefineCFOP } from '~/pages/EmissaoNFE/functions/Auxiliares';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import { insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';

const MySwal = withReactContent(Swal);

export const FormItens: React.FC = () => {
  const {
    codLoja,
    loja,
    isBonificado,
    setIsBonificado,
    produtos,
    setProdutos,
    isUpdate,
    formNFE: { getValues: getFormNFEValues },
    formItens: {
      register,
      handleSubmit,
      control,
      setFocus,
      setValue,
      getValues,
      clearErrors,
      formState,
      watch,
      reset,
    },
    isFormItendEditing,
    produtoSelecionado,
    setProdutoSelecionado,
    setIsFormItendEditing,
    listaDeCfop,
    masterDetail,
    setMasterDetail,
  } = useEmissaoNFE();

  const [qtd_entrada, val_tabela, desconto, acrescimo] = watch([
    'qtd_entrada',
    'val_tabela',
    'desconto',
    'acrescimo',
  ]);
  const [subTotal, setSubTotal] = useState('0');
  const [subTotalIsNegative, setSubTotalIsNegative] = useState(false);
  const [cfopOptions, setCfopOptions] = useState<SelectType[]>([]);

  useEffect(() => {
    const formatedQtdEmbalagemVenda = qtd_entrada || 0;
    let formatedValorUnitario = val_tabela || 0;
    let formatedAcrescimo = acrescimo || 0;
    let formatedDesconto = desconto || 0;

    if (val_tabela && val_tabela !== '') {
      formatedValorUnitario = Number(
        String(val_tabela).replace('.', '').replace(',', '.'),
      );
    }
    if (acrescimo && acrescimo !== '') {
      formatedAcrescimo = Number(
        String(acrescimo).replace('.', '').replace(',', '.'),
      );
    }
    if (desconto && desconto !== '') {
      formatedDesconto = Number(
        String(desconto).replace('.', '').replace(',', '.'),
      );
    }

    // const mult = formatedQtdEmbalagemVenda * formatedValorUnitario;
    // const sub = formatedAcrescimo - formatedDesconto;
    // const subTotalCalculado = mult + sub;
    const subTotalCalculado =
      formatedQtdEmbalagemVenda *
      (formatedValorUnitario - formatedDesconto + formatedAcrescimo);

    if (typeof subTotalCalculado === 'number') {
      const novoSubTotal = moneyFormat(String(subTotalCalculado), 2);
      setSubTotal(novoSubTotal);
      setValue('subtotal', novoSubTotal);

      if (subTotalCalculado < 0) {
        setSubTotalIsNegative(true);
        return;
      }
      setSubTotalIsNegative(false);
    }
  }, [acrescimo, desconto, qtd_entrada, setValue, val_tabela]);

  const handleLimpar = useCallback(() => {
    reset(itensNfe);
    setIsBonificado(false);
    setProdutoSelecionado({} as Produto);
    setIsFormItendEditing(false);
    FocusFilter('produto');
  }, [reset, setIsBonificado, setIsFormItendEditing, setProdutoSelecionado]);

  async function FocusFilter(id: string) {
    const input = await document.getElementById(id);
    input?.focus();
  }
  const handleAddProduto = handleSubmit(async (data) => {
    const { flg_orgao_publico } = getFormNFEValues('busca_parceiro');
    const item: any = getValues('busca_produto');

    if (item.flg_ipv === false && !Number.isInteger(Number(data.qtd_entrada))) {
      toast.warn(
        'Quantidade fracionada é permitida apenas para itens de peso variável.',
      );
      return;
    }

    const formatedItem = await CalculosDaTabelaManual({
      item,
      qtd_entrada: data.qtd_entrada,
      val_tabela_str: data.val_tabela,
      val_desconto_str: data.desconto,
      val_acrescimo_str: data.acrescimo,
      flg_orgao_publico,
      tipo_tributacao: getValues('tributacao')?.tipo_tributacao,
      tribData: getValues('tributacao'),
      cod_situacao_tributaria: getValues('tributacao')?.cod_situacao_tributaria,
      cod_tributacao: getValues('tributacao')?.value,
      val_reducao_base_calculo: getValues('tributacao')?.val_reducao,
      per_icms: getValues('tributacao')?.val_icms,
      tipo_regime: loja.tipo_regime,
      des_sigla: loja.uf,
      cfop: getValues('cfop')?.cfop,
      des_cfop: getValues('cfop')?.des_cfop,
      cfopData: getValues('cfop'),
      bonificado: isBonificado,
      lastIndex: produtos.length,
      produtoSelecionado,
    });
    formatedItem.uuid = isFormItendEditing.isEdit
      ? isFormItendEditing.uuid
      : nanoid();

    /**
     * No caso de inserção de um item que já existe na tabela
     */
    const produtoExists = produtos.find(
      (produto) =>
        produto.cod_produto === formatedItem.cod_produto &&
        produto.flg_bonificado === formatedItem.flg_bonificado &&
        produto.uuid !== isFormItendEditing.uuid,
    );
    if (produtoExists) {
      const confirmaSubstituicao = await MySwal.fire({
        title: 'Item já existente na tabela',
        text: 'Deseja substituir?',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (confirmaSubstituicao) {
        formatedItem.uuid = produtoExists.uuid;
        const produtosDetail: any[] = await insertOrUpdate(
          'produtos',
          formatedItem,
          masterDetail,
          setMasterDetail,
        );
        setProdutos(produtosDetail);
      }
      handleLimpar();
      return;
    }

    /**
     * Caso não esteja editando o item e ele ainda não exista na tabela
     */
    if (produtos.length + 1 > 500) {
      toast.warning('Limite de 500 items excedido.');
      return;
    }

    const produtosDetail: any[] = await insertOrUpdate(
      'produtos',
      formatedItem,
      masterDetail,
      setMasterDetail,
    );
    setProdutos(produtosDetail);
    handleLimpar();
  });

  const handleSelectedProduct = useCallback(
    (selected: any) => {
      const perfil = getFormNFEValues('cod_perfil')?.value;
      if (perfil === undefined) {
        toast.warning('Perfil deve ser informado.');
        return;
      }
      /** Definine o CFOP */
      const cfop: any = DefineCFOP(
        listaDeCfop,
        selected.tipo_tributacao,
        true,
        loja?.uf === getFormNFEValues('busca_parceiro')?.des_uf,
        selected,
      );
      if (typeof cfop === 'object') {
        setValue('cfop', {
          value: cfop.value,
          label: cfop.label,
          ...cfop,
        });
      }
      const selectedAny: any = selected;
      clearErrors('busca_produto');
      setValue('busca_produto', selected);
      setValue('tributacao', {
        value: selectedAny.cod_trib_entrada,
        label: `${selectedAny.cod_trib_entrada} - ${selectedAny.des_tributacao}`,
        cod_tributacao: selectedAny.cod_trib_entrada,
        des_tributacao: selectedAny.des_tributacao,
        val_icms: selectedAny.val_icms,
        val_reducao: selectedAny.val_reducao,
        tipo_tributacao: selectedAny.tipo_tributacao,
        cod_bc_mr: selectedAny.cod_bc_mr,
        cod_situacao_tributaria: selectedAny.cod_situacao_tributaria,
        cod_tributacao_pdv: selectedAny.des_tributcod_tributacao_pdvacao,
      });
      setFocus('qtd_entrada');
      if (selected.val_preco) {
        setValue('val_tabela', moneyFormat(selected.val_preco, 2));
      }
    },
    [clearErrors, getFormNFEValues, listaDeCfop, loja?.uf, setFocus, setValue],
  );

  useEffect(() => {
    const options = listaDeCfop.map((cfop: ListaDeCFOP) => ({
      label: cfop.cfop,
      value: cfop.des_cfop,
      ...cfop,
    }));
    setCfopOptions(options);
  }, [listaDeCfop]);

  return (
    <Container>
      <Separator labelText="Itens da NF" sidePadding="0 0" />
      <Row>
        <Col sm={12} md={10}>
          <InputAsyncSelect
            label="Busca Produto"
            maxLength={50}
            placeholder="Selecione..."
            name="busca_produto"
            onBlur={() => {
              const v = getValues('busca_produto');
              if (typeof v === 'undefined') clearErrors('busca_produto');
            }}
            id="produto"
            register={register}
            isError={!!formState.errors.busca_produto}
            control={control}
            disabled={isUpdate || isFormItendEditing.isEdit}
            changeSelected={(selected) => {
              handleSelectedProduct(selected);
              clearErrors();
            }}
            api={{
              route: '/emissao-nfe/produtos',
              method: 'get',
              bodyParams: {
                loja: codLoja,
              },
              fields: ['cod_produto', 'des_produto'],
              dependsOf: ['loja'],
              messageForDependsOf:
                'É necessário selecionar a loja na capa da NF',
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <ConfirmButton
            onClick={() => {
              handleLimpar();
            }}
            style={{
              color: '#ffffff',
              background: '#cf9d08',
              width: '100%',
              marginTop: '28px',
            }}
            disabled={isUpdate}
          >
            Limpar
          </ConfirmButton>
        </Col>
        <Col sm={12} md={5}>
          <InputAsyncSelect
            label="Tributação"
            maxLength={50}
            placeholder="Selecione..."
            name="tributacao"
            register={register}
            isError={!!formState.errors.tributacao}
            control={control}
            disabled={isUpdate}
            changeSelected={(selected) => {
              clearErrors('tributacao');
              setValue('tributacao', selected);
            }}
            api={{
              route: '/emissao-nfe/tributacao',
              method: 'get',
              fields: ['cod_tributacao', 'des_tributacao'],
              searchBeforeFilter: true,
            }}
          />
        </Col>
        <Col sm={12} md={5}>
          <InputSelect
            label="CFOP"
            maxLength={50}
            placeholder="Selecione..."
            name="cfop"
            register={register}
            isError={!!formState.errors.cfop}
            control={control}
            disabled={isUpdate}
            options={cfopOptions}
            changeSelected={(selected) => {
              clearErrors('cfop');
              setValue('cfop', selected);
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <div
            style={{
              marginTop: '35px',
            }}
          >
            <ToggleDefault
              labelText="Bonificado?"
              setChecked={isBonificado}
              inLine
              disabled={!getValues('cfop')?.value || isUpdate}
              onSwitch={() => {
                if (getValues('cfop')?.value.slice(-3) !== '910') {
                  toast.warning('Código Fiscal deve ser de bonificação.');
                  return;
                }
                setValue('bonificado', !isBonificado);
                setIsBonificado(!isBonificado);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={4}>
          <EntradaItensContainer>
            <Row className="bg-green">
              <Col sm={12} md={6}>
                <InputNumber
                  label="Quantidade"
                  max={10000}
                  maxLength={50}
                  placeholder="0"
                  name="qtd_entrada"
                  register={register}
                  disabled={isUpdate}
                  isError={!!formState.errors.qtd_entrada}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleAddProduto();
                      clearErrors();
                    }
                  }}
                />
              </Col>
              <Col sm={12} md={6}>
                <InputMoney
                  label="Valor Unitário"
                  placeholder="0,00"
                  min={0}
                  decimals={2}
                  name="val_tabela"
                  register={register}
                  control={control}
                  disabled={isUpdate}
                  isError={!!formState.errors.val_tabela}
                  showIcon={false}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleAddProduto();
                      clearErrors();
                    }
                  }}
                />
              </Col>
            </Row>
          </EntradaItensContainer>
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Desconto"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="desconto"
            register={register}
            control={control}
            disabled={isUpdate}
            isError={!!formState.errors.desconto}
            showIcon={false}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Acréscimo"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="acrescimo"
            register={register}
            control={control}
            disabled={isUpdate}
            isError={!!formState.errors.acrescimo}
            showIcon={false}
          />
        </Col>
        <Col
          sm={12}
          md={2}
          className={`sub-total ${subTotalIsNegative ? 'negative' : ''}`}
        >
          <span
            title={`${subTotalIsNegative ? 'SubTotal deve ser positivo' : ''}`}
          >
            SubTotal: {subTotal}
          </span>
        </Col>
        <Col sm={12} md={2} className="d-flex justify-content-end add">
          <Button
            variant="success"
            className="mt-auto mb-2 w-100"
            onClick={handleAddProduto}
            disabled={isUpdate}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
